<template>
    <TsPanelCustomScroll :loading="loading">
        <ts-page-title
            :title="$t('shop.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                { text: $t('shop.pageTitle'), href: '/admin/agency/shops' },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <div class="row">
            <div class="col-md-4 tw-space-y-4 tw-mb-3 sm:tw-mb-0 lg:tw-mb-0">
                <!-- basic info -->
                <BasicInfo v-model="model" :validate="errors" />
            </div>
            <div class="col-md-8 tw-space-y-4">
                <!-- bank account -->
                <div class="row">
                    <div class="col-md-12">
                        <BankAccount
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            style="min-height: 200px"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ExchangeRate
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            style="min-height: 200px"
                        />
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-12">
                <ts-panel>
                    <ts-panel-wrapper>
                        <h4
                            class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
                        >
                            {{ $t("agencyProfile.address") }}
                        </h4>
                        <div class="row">
                            <div class="col-md-4">
                                <AddressShop
                                    v-model="model"
                                    :validate="errors"
                                    @loading="v => (loading = v)"
                                />
                                <br />
                            </div>
                            <div class="col-md-8">
                                <!-- geolocation -->
                                <GeoLocation
                                    v-model="model"
                                    ref="goelocation"
                                />
                                <div
                                    class="tw-text-red-500"
                                    v-if="
                                        errors.has('geo_location.lat') ||
                                            errors.has('geo_location.lng')
                                    "
                                >
                                    {{ errors.first("geo_location.lng") }}
                                </div>
                            </div>
                        </div>
                    </ts-panel-wrapper>
                </ts-panel>
            </div>
        </div>
        <template v-slot:footer>
            <ts-button
                @click.prevent="$router.push({ name: 'shop' })"
                :disabled="loading"
                >{{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                @click.prevent="onUpdate"
                :disabled="loading"
                >{{ $t("update") }}</ts-button
            >
        </template>
    </TsPanelCustomScroll>
</template>

<script>
import { Errors } from "form-backend-validation";
import AddressShop from "./components/address.vue";
import GeoLocation from "./components/geolocaiton.vue";
import BankAccount from "./components/bank-account.vue";
import BasicInfo from "./components/basic-info.vue";
import ExchangeRate from "./components/exchange-rate.vue";

import { mapActions, mapState } from "vuex";
export default {
    name: "shopEdit",
    components: {
        AddressShop,
        GeoLocation,
        BankAccount,
        BasicInfo,
        ExchangeRate
    },
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                shop_id: "",
                shop_code: "",
                shop_name: "",
                phone_number: "",
                email: "",
                business_category_id: undefined,
                photo: "",
                geo_location: {
                    lat: "",
                    lng: ""
                },
                province_code: undefined,
                district_code: undefined,
                commune_code: undefined,
                village_code: undefined,
                payment_info: [],
                acknowledge_tc: false,
                shop_type_id: undefined,
                refer_from_agency_id: undefined,
                is_active: true,
                express_fee_usd: "",
                standard_fee_usd: "",
                express_driver_fee_usd: "",
                standard_driver_fee_usd: "",
                exchange_rate: [],
                is_stock_at_jd: false,
                fee_per_package: 0,
                location_name: undefined,
                service_type_allow: [],
                service_paid_by: 2, // default by shop
                saler_id: undefined
            }
        };
    },
    computed: {
        ...mapState("agency/shop", ["edit_data"])
    },
    methods: {
        ...mapActions("agency/shop", ["getFormViewData", "find"]),
        fetchFormView(control, params) {
            this.loading = true;
            this.getFormViewData({ params: params, control: control })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.loading = true;
            this.$store
                .dispatch("agency/shop/update", {
                    id: this.model.shop_id,
                    data: this.model
                })
                .then(async response => {
                    await this.uploadLogo(this.model.shop_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "shop" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async uploadLogo(shopId) {
            if (this.model.photo instanceof File) {
                let formData = new FormData();
                formData.append("file", this.model.photo);
                formData.append("shop_id", shopId);
                let response = await this.$store.dispatch(
                    "agency/shop/upload",
                    formData
                );
                return response;
            }
            return "";
        },
        async setEdit() {
            await this.find(this.$route.params.id);
            this.fetchFormView(false, {
                params: {
                    provinceCode: this.edit_data.province_code,
                    districtCode: this.edit_data.district_code,
                    communeCode: this.edit_data.commune_code
                }
            });
            this.model.shop_id = this.edit_data.shop_id;
            this.model.shop_name = this.edit_data.shop_name;
            this.model.phone_number = this.edit_data.phone_number;
            this.model.email = this.edit_data.email;
            this.model.business_category_id = this.edit_data.business_category_id;
            this.model.photo = this.edit_data.photo;
            this.model.province_code = this.edit_data.province_code;
            this.model.district_code = this.edit_data.district_code;
            this.model.commune_code = this.edit_data.commune_code;
            this.model.village_code = this.edit_data.village_code;
            this.model.payment_info = this.edit_data.payment_info;
            this.model.acknowledge_tc = this.edit_data.acknowledge_tc;
            this.model.shop_type_id = this.edit_data.shop_type_id;
            this.model.refer_from_agency_id = this.edit_data.refer_from_agency_id;
            this.model.is_active = this.edit_data.is_active;
            this.model.express_fee_usd = this.edit_data.express_fee_usd;
            this.model.standard_fee_usd = this.edit_data.standard_fee_usd;
            this.model.express_driver_fee_usd = this.edit_data.express_driver_fee_usd;
            this.model.standard_driver_fee_usd = this.edit_data.standard_driver_fee_usd;
            this.model.is_stock_at_jd = this.edit_data.is_stock_at_jd;
            this.model.fee_per_package = this.edit_data.fee_per_package;
            this.model.location_name = this.edit_data.location_name;
            this.model.service_paid_by = this.edit_data.service_paid_by;
            this.model.saler_id = this.edit_data.saler_id;
            this.model.service_type_allow = JSON.parse(
                this.edit_data.service_type_allow
            ).map(el => parseInt(el.service_type_id));
            this.model.exchange_rate = this.edit_data.exchange_rate.map(el => ({
                currency_id_from: el.currency_id_from,
                currency_id_to: el.currency_id_to,
                fx_rate: el.fx_rate
            }));

            if (this.edit_data.geo_location) {
                this.model.geo_location.lat = this.edit_data.geo_location[0].lat;
                this.model.geo_location.lng = this.edit_data.geo_location[0].lng;
                this.$refs.goelocation.center = this.model.geo_location;
                this.$refs.goelocation.marker = this.model.geo_location;
                this.$refs.goelocation.zoom = 18;
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.setEdit();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("agency/shop/SET_EDIT_DATA", []);
        this.$store.commit("agency/shop/RESET_STATE");
        next();
    }
};
</script>
